import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import outlook_web_6 from '../../../../../../../common/src/assets/image/outlook_web_6.png';
import outlook_web_1 from '../../../../../../../common/src/assets/image/outlook_web_1.png';
import outlook_web_2 from '../../../../../../../common/src/assets/image/outlook_web_2.png';
import outlook_web_3 from '../../../../../../../common/src/assets/image/outlook_web_3.png';
import outlook_web_4 from '../../../../../../../common/src/assets/image/outlook_web_4.png';
import outlook_web_5 from '../../../../../../../common/src/assets/image/outlook_web_5.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        containing events that are defined in Outlook.com calendars. You can connect via an iCalendar (ics) URL as described below, but we recommend connecting via the {' '}
        <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Outlook-Local">
          Microsoft 365 / Outlook 365
        </Link>{' '}
        instructions instead. In any case, here's how to add Outlook.com iCalendar URL calendar data
        sources to PrintableCal:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button on the Calendar Data
          Sources step and select the Outlook.com option. The{' '}
          <strong>Add Calendar Source - Outlook.com</strong> window will appear.
          <br />
          <Image alt="" src={outlook_web_6} style={{ width: 690 }} />
        </li>
        <li>
          Open{' '}
          <a
            href="https://www.outlook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.outlook.com
          </a>{' '}
          in your web browser. Log into your account if needed.
        </li>
        <li>
          Click the button near the upper-left corner of the Outlook.com web
          page.
        </li>
        <li>
          Click the <strong>Calendar</strong> tile.
          <br />
          <Image alt="" src={outlook_web_1} style={{ width: 417 }} />
        </li>
        <li>
          Click the gear button in the upper-right corner and select{' '}
          <strong>View all Outlook settings</strong>.<br />
          <Image alt="" src={outlook_web_2} style={{ width: 358 }} />
        </li>
        <li>
          Click <strong>Shared calendars</strong>, and select the desired
          calendar from the <strong>Select a calendar</strong> drop-down list
          under <strong>Publish a calendar</strong>.
        </li>
        <li>
          Select <strong>Can view all details</strong> from the{' '}
          <strong>Select permissions</strong> drop-down list.
        </li>
        <li>
          Click the <strong>Publish</strong> button.
          <br />
          <Image alt="" src={outlook_web_3} />
        </li>
        <li>
          Click the <strong>ICS</strong> URL and select{' '}
          <strong>Copy link</strong> from the popup menu.
          <br />
          <Image alt="" src={outlook_web_4} style={{ width: 690 }} />
        </li>
        <li>
          After copying the link, the address field at the top of the{' '}
          <strong>Add Calendar Source - Outlook.com </strong>window should be
          automatically filled. If that doesn't happen, right-click the address
          field and select <strong>Paste</strong> from the popup menu, or press{' '}
          <strong>Ctrl+V</strong>.
        </li>
        <li>
          Enter a name for your Outlook.com calendar in the field below the URL
          field.
          <br />
          <Image alt="" src={outlook_web_5} style={{ width: 690 }} />
        </li>
        <li>
          The OK button will now be enabled. Click the OK button to add your
          Outlook.com calendar to PrintableCal's list of available calendar
          sources.
        </li>
      </ol>
      <h2>How to import events that are more than 6 months in the future</h2>
      <p>
        By default, calendars that are shared by Outlook.com through an ICS URL
        will only show events occurring up to 6 months in the future. If you
        need to import events for a whole year, have your Office 365
        administrator follow these steps:
      </p>
      <ol>
        <li>
          <a
            href="https://technet.microsoft.com/en-us/library/jj984289%28v=exchg.160%29.aspx?f=255&MSPPError=-2147217396"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            Use remote PowerShell to connect to Exchange Online
          </a>
          .
        </li>
        <li>
          Run the following command, replacing "youremail@yourdomain.com" with
          your Outlook.com email address:
          <br />
          <code>
            <strong>
              Set-MailboxCalendarFolder -Identity
              youremail@yourdomain.com:\Calendar –PublishEnabled $true
              -PublishDateRangeFrom SixMonths -PublishDateRangeTo OneYear
            </strong>
          </code>
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Outlookcom"
      commentsId="commentsplus_post_142_489"
      title="Print Outlook.com Calendar"
      description="PrintableCal can create printable calendars containing events that are defined in Outlook.com (aka Hotmail or Windows Live) calendars."
      keywords="print Outlook.com calendar, print Hotmail calendar, print Windows Live calendar, print calendar, Excel calendar, Word calendar, calendar templates, import Hotmail calendar, import Windows calendar"
      content={content}
    />
  );
};

export default Documentation;
